import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistesValidationErrorDto } from '../../../../services/generated/FrontOffice-api';
import ModalErrorInformation from '../../../basics/ModalErrorInformation';

interface ModalValidationArtisteProps {
	isOpen: boolean;
	onCancel: () => void;
	isLoading?: boolean;
	errorsValidation: ArtistesValidationErrorDto[];
}

const ModalValidationArtiste: React.FunctionComponent<ModalValidationArtisteProps> = ({
	isOpen,
	onCancel,
	isLoading,
	errorsValidation
}) => {
	const { t } = useTranslation();

	return (
		<ModalErrorInformation
			isOpen={isOpen}
			onCancel={onCancel}
			isLoading={isLoading}
			title={t("validation-artiste.title")}
			cancelButtonLabel={t("common.return")}
		>
			<div className="modal__item creationDossier__validationArtisteModal">
				<ul>
					{errorsValidation?.map((element, idx) => (
						<li key={idx}>
							{element.hasDateWithoutCachet &&
								<div>{t("validation-artiste.hasDateWithoutCachet")}</div>
							}
							{element.hasMinimumNbArtisteEngagement &&
								<div>{t("validation-artiste.hasMinimumNbArtisteEngagement", {
									number: element.nombreLimiteArtisteEngagementDirect
								})}</div>
							}
							{element.emploiComparisonConstraint && element.emploiComparisonConstraint.more.length > 0 &&
								element.emploiComparisonConstraint.more.map((item, idx) => (
									<div key={'eccm-'+idx}>{t("validation-artiste.emploiComparisonConstraintMore")}
										<span>{t(`emplois.${item}`)}</span>
									</div>
								))
							}
							{element.emploiComparisonConstraint && element.emploiComparisonConstraint.less.length > 0 &&
								element.emploiComparisonConstraint.less.map((item, idx) => (
									<div key={'eccl-'+idx}>{t("validation-artiste.emploiComparisonConstraintLess")}
										<span>{t(`emplois.${item}`)}</span>
									</div>
								))
							}
							{element.shouldHaveLess && element.shouldHaveLess.length > 0 &&
								element.shouldHaveLess.map((item, idx) => (
									<div key={'shl-'+idx}>{t("validation-artiste.shouldHaveLess")}
										<span>{t(`emplois.${item}`)}</span>
									</div>
								))
							}
							{element.than && element.than.length > 0 &&
								element.than.map((item, idx) => (
									<div key={'t-'+idx}>{t("validation-artiste.than")}
										<span>{t(`emplois.${item}`)}</span>
									</div>
								))
							}
							{element.surpassedMensualisationLimit &&
								<div>{t("validation-artiste.surpassedMensualisationLimit", {number: element.surpassedMensualisationLimit})}</div>
							}
							{element.typeCachetRequired && element.typeCachetRequired.length > 0 &&
								element.typeCachetRequired.map((item, idx) => (
									<div key={'tcr-'+idx}>
										{t("validation-artiste.typeCachetRequired", {item:item})}
									</div>
								))
							}
							{element.minimumEmploiCountConstraint && element.minimumEmploiCountConstraint.length > 0 &&
								element.minimumEmploiCountConstraint.map((item, idx) => (
									<div key={'mecc-'+idx}>
										{t("validation-artiste.minimumEmploiCountConstraint1")} {item.minimum} {item.emplois.map((e, i) => (
											<span className="textLowerCase">
												{t('emplois.' + e)} {(item.emplois.length > 1 && i < (item.emplois.length - 1)) &&  `${t("common.or")} `}
											</span>
										))} {t("validation-artiste.minimumEmploiCountConstraint2")}
									</div>
								))
							}
							{element.numberOfArtisteMustBeTheSameForEachRepresentationDate &&
								<div>{t("validation-artiste.numberOfArtisteMustBeTheSameForEachRepresentationDate")}</div>
							}
							{element.numberOfAudioArtistMustBeInferiorToVisualArtist &&
								<div>{t("validation-artiste.numberOfAudioArtistMustBeInferiorToVisualArtist")}</div>
							}
							{element.artisteWithLessDateInDifferentPlaceThan && element.artisteWithLessDateInDifferentPlaceThan !== 0 &&
								<div>
									{t("validation-artiste.artisteWithLessDateInDifferentPlaceThan", {
										number: element.artisteWithLessDateInDifferentPlaceThan,
										name: element.artisteWithLessDateInDifferentPlaceThanFullName
									})}
								</div>
							}
							{element.nombreLimiteArtiste && element.nombreLimiteArtiste > 0
								? <div>
									{t("validation-artiste.nombreLimiteArtiste", {
										number: element.nombreLimiteArtiste
									})}
								</div>
								: <></>
							}
						</li>
					))}
				</ul>
			</div>
		</ModalErrorInformation>
	);
}

export default ModalValidationArtiste;