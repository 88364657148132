import React from "react";
import { parseAnswerValue, shouldDisplayChoice, shouldDisplayQuestion, } from "../../../utils/questionHelpers";
import SimpleQuestionInput from "./questions/SimpleQuestionInput";
import YesNoQuestionInput from "./questions/YesNoQuestionInput";
import ChoiceListQuestionInput from "./questions/ChoiceListQuestionInput";
import DateQuestionInput from "./questions/DateQuestionInput";
import PeriodQuestionInput from "./questions/PeriodQuestionInput";
import FileQuestionInput from "./questions/FileQuestionInput";
import ArrayQuestionInput from "./questions/ArrayQuestionInput";
import { AnswerType } from '../../../models/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
export const getQuestions = (block, answers) => {
    if (!block.questions) {
        return [];
    }
    // Determine which questions should be visible based on current answers
    const isVisible = {};
    for (const q of block.questions) {
        isVisible[q.id] = shouldDisplayQuestion(q, answers);
    }
    // Remove questions that depend on questions that are not visible (repeat until none are found)
    // This could happen if the answer to a previous question changes. For instance:
    // - question B is visible if the answer to question A is "true"
    // - question C is visible if the answer to question B is "true"
    // - user answers "true" to question A, which makes question B visible
    // - user answers "true" to question B, which makes question C visible
    // - user changes their answer to question A to "false"
    // - question B becomes hidden, so question C should become hidden as well,
    //   even though we have a "true" answer to B
    let removedAny;
    do {
        removedAny = false;
        for (const q of block.questions) {
            if (!isVisible[q.id]) {
                continue;
            }
            // If question depends on a question that is not visible
            if (q.conditions && q.conditions.some(c => !isVisible[c.questionId])) {
                // Hide that question
                isVisible[q.id] = false;
                removedAny = true;
            }
        }
    } while (removedAny);
    return block.questions.filter(q => isVisible[q.id]);
};
export const getAnswer = (question, answers) => {
    if (answers) {
        let value = !answers.hasOwnProperty(question.id) && question.presetAnswer
            ? question.presetAnswer.value
            : answers[question.id];
        return parseAnswerValue(value, question.answerType);
    }
};
const RenderBlockContent = ({ block, answers, lang }) => {
    const { t } = useTranslation();
    const renderQuestion = (question, block, currentValue) => {
        var _a;
        if (question.id == "duree-estimee" && question.answerType == AnswerType.IntegerNumber) {
            currentValue = (_a = answers["duree-estimee"]) === null || _a === void 0 ? void 0 : _a.toString();
        }
        const questionId = block.id + "-" + question.id;
        return renderQuestionInput(question, currentValue, questionId);
    };
    const renderQuestionInput = (question, currentValue, questionId) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        switch (question.answerType) {
            case AnswerType.Text:
            case AnswerType.MultilineText:
            case AnswerType.Html:
            case AnswerType.IntegerNumber:
            case AnswerType.DecimalNumber:
                return React.createElement(SimpleQuestionInput, { title: (_a = question.text) === null || _a === void 0 ? void 0 : _a[lang], isUrl: false, id: questionId, question: question, currentValue: currentValue || "" });
            case AnswerType.Link:
            case AnswerType.Url:
                return React.createElement(SimpleQuestionInput, { title: (_b = question.text) === null || _b === void 0 ? void 0 : _b[lang], isUrl: true, id: questionId, question: question, currentValue: currentValue });
            case AnswerType.YesNo:
            case AnswerType.YesNoDontKnow:
                return React.createElement(YesNoQuestionInput, { title: (_c = question.text) === null || _c === void 0 ? void 0 : _c[lang], id: questionId, question: question, currentValue: currentValue, t: t });
            case AnswerType.File:
                return React.createElement(FileQuestionInput, { title: (_d = question.text) === null || _d === void 0 ? void 0 : _d[lang], id: questionId, question: question, currentValue: currentValue });
            case AnswerType.Date:
                return React.createElement(DateQuestionInput, { title: (_e = question.text) === null || _e === void 0 ? void 0 : _e[lang], id: questionId, question: question, currentValue: currentValue });
            case AnswerType.Period:
                return React.createElement(PeriodQuestionInput, { title: (_f = question.text) === null || _f === void 0 ? void 0 : _f[lang], id: questionId, question: question, currentValue: currentValue });
            case AnswerType.SingleChoiceList:
                return React.createElement(ChoiceListQuestionInput, { title: (_g = question.text) === null || _g === void 0 ? void 0 : _g[lang], id: questionId, question: question, currentValue: currentValue, choiceFilter: c => shouldDisplayChoice(c, answers), isMulti: false, lang: lang });
            case AnswerType.MultipleChoiceList:
                return React.createElement(ChoiceListQuestionInput, { title: (_h = question.text) === null || _h === void 0 ? void 0 : _h[lang], id: questionId, question: question, currentValue: currentValue, choiceFilter: c => shouldDisplayChoice(c, answers), isMulti: true, lang: lang });
            case AnswerType.Array:
                return React.createElement(ArrayQuestionInput, { title: (_j = question.text) === null || _j === void 0 ? void 0 : _j[lang], id: questionId, question: question, currentValue: currentValue, renderQuestionInput: renderQuestionInput });
            default:
                console.warn(`Question type "${question.answerType}" not supported`);
                return null;
        }
    };
    return (React.createElement(React.Fragment, null, block.questions && getQuestions(block, answers)
        .filter(q => q.id !== "nom-du-projet" && !(block.id === "contenu-projet" && q.id === "description"))
        .map(question => (React.createElement("div", { key: question.id }, renderQuestion(question, block, getAnswer(question, answers)))))));
};
export default RenderBlockContent;
