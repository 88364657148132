import React from "react";
import QuestionInputProps from "./QuestionInputProps";
import { useTranslation } from "react-i18next";
import { AnswerType } from "../../../../services/generated/FrontOffice-api";
import InputRadioButton from 'adel-shared/dist/components/basics/InputRadioButton';

const YesNoQuestionInput: React.FunctionComponent<QuestionInputProps> = ({
	id,
	title,
	question,
	onAnswerChange,
	errorMessage,
	currentValue,
	isDisabled
}) => {
	const { t } = useTranslation();

	const handleChange = (isChecked: boolean, value: boolean | null) => {
		if(isChecked && onAnswerChange) {
			onAnswerChange(question?.id, value);
		}
	}

	return <div>
		<div className="questionField__title">
			<span dangerouslySetInnerHTML={{ __html: title }} /> {question?.isRequired && "*"}
		</div>
		<div className="questionField">	
					<InputRadioButton
						name={id}
						label={t("common.yes")}
						id={id + "-yes"}
						onChange={e => handleChange(e.target.checked, true)}
						checked={currentValue == true}
						isDisabled={isDisabled}

					/>
					<InputRadioButton
						name={id}
						label={t("common.no")}
						id={id + "-no"}
						onChange={e => handleChange(e.target.checked, false)}
						checked={currentValue == false}
						isDisabled={isDisabled}
					/>
				{question.answerType === AnswerType.YesNoDontKnow &&
					<InputRadioButton
						name={id}
						label={t("common.dontknow")}
						id={id + "-dontknow"}
						onChange={e => handleChange(e.target.checked, null)}
						checked={currentValue === null}
						isDisabled={isDisabled}
					/>
			}
		</div>
		{errorMessage && errorMessage.message && errorMessage.message !== '' &&
			<p className="input__errorMessage">{errorMessage.message}</p>
		}
	</div>;
}

export default YesNoQuestionInput;