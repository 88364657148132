import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import moment from 'moment';
import React, {
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
	useCallback
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAxios } from '../../../../custom-hooks/useAxios';
import useValidation from '../../../../custom-hooks/useValidation';
import { CategorieDossier } from '../../../../enums/Dossiers';
import {
	ArtisteClient,
	ArtisteDto,
	CachetDto,
	CreateArtisteDto,
	CreateCachetDto,
	DossierArtistesViewModelDto,
	DossierClient,
	Emploi,
	LieuDateDto,
	RemunerationDto,
	SalaireDto,
	TypePrestation,
	UpdateArtisteDto,
	UpdateCachetDto
} from '../../../../services/generated/FrontOffice-api';
import { formMode } from '../ContratsCachets';
import Cachets from './Cachets';
import Salaires from './Salaires';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';

export interface EditArtisteRef {
	validateAndSend: () => Promise<boolean>;
}

export interface LieuDateByPrestation {
	typePresta: TypePrestation;
	dates: LieuDateDto[];
}
export interface SalairesCusto extends SalaireDto {
	lieuDateByPresta: LieuDateByPrestation[];
}

export interface DatesSelectOption {
	typePresta: keyof typeof TypePrestation,
	options: AdelOption<LieuDateDto>[]
}

interface EditArtisteProps {
	dossierId: string;
	closeView: () => void;
	setIsFormValid: (value: boolean) => void;
	artisteSelected: ArtisteDto;
	currentFormMode: formMode;
	codeCategorie: CategorieDossier;
}

const EditArtiste: ForwardRefExoticComponent<PropsWithoutRef<EditArtisteProps> & RefAttributes<EditArtisteRef>> = forwardRef(({
	dossierId,
	closeView,
	setIsFormValid,
	artisteSelected,
	currentFormMode,
	codeCategorie
}, ref) => {
	const { t, i18n } = useTranslation();
	const form = useForm<CreateArtisteDto>({defaultValues: {
		instrumentId: artisteSelected?.instrument?.id,
		formation: artisteSelected?.formation
	}});

	const { getRootValidator } = useValidation();
	const artisteValidator = getRootValidator("CreateArtisteDto");
	const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const artisteClient = useMemo(() => { return new ArtisteClient("", axiosInstance) }, [axiosInstance]);

	const emploi = form.watch("emploi");
	const styleMusical = form.watch("stylemusical");

	const [isArtistePrincipal, setIsArtistePrincipal] = useState<boolean>(false);
	const [isArtisteTitulaire, setIsArtisteTitulaire] = useState<boolean>();
	const [isArtisteAdditionnel, setIsArtisteAdditionnel] = useState<boolean>();
	const [isArtistGenerationSpedidam, setIsArtistGenerationSpedidam] = useState<boolean>(false);

	const YesNotOptions = [
		{ value: "oui", label: t("common.yes") },
		{ value: "non", label: t("common.no") }
	]


	//L'ensemble des types de cachets
	const [enregistrements, setEnregistrements] = useState<CachetDto[]>([]);
	const [repetitions, setRepetitions] = useState<CachetDto[]>([]);
	const [representations, setRepresentations] = useState<CachetDto[]>([]);
	const [diffusion, setDiffusion] = useState<CachetDto[]>([]);
	const [concert, setConcert] = useState<CachetDto[]>([]);

	const [salaires, setSalaires] = useState<SalairesCusto[]>([]);
	const [viewModel, setViewModel] = useState<DossierArtistesViewModelDto>({});
	const [datesSelectOptions, setDatesSelectOptions] = useState<DatesSelectOption[]>([]);

	//infos extraites de viewmodel
	const [instrumentsOptions, setInstrumentsOptions] = useState<AdelOption<string>[]>([]);
	const [stylesMusicauxOptions, setStylesMusicauxOptions] = useState<AdelOption<string>[]>([]);
	const [emploisOptions, setEmploisOptions] = useState<AdelOption<Emploi>[]>([]);

	const [invalidSalaireDates, setInvalidSalaireDates] = useState<boolean>(false);
	const [remunerations, setRemunerations] = useState<RemunerationDto[]>([]);
	const [groupesEmploi, setGroupesEmploi] = useState<RemunerationDto[]>([]);

	useEffect(() => {
		setIsFormValid(true);
		(async() => {
			getArtistesViewModel();
		})();
	}, []);
	const getArtistesViewModel = async() => {
		const result = await dossierClient.getArtistesViewModel(dossierId)
		setViewModel(result)

		//récupération rémunérations minimum
		setRemunerations(result.remunerations);
		setGroupesEmploi(result.groupesEmploi);

		//on récupère la liste d'instruments
		if (result?.instruments) {
			const instruments = result.instruments.map(instrument => ({
				label: instrument.intitule[i18n.language] ?? "",
				value: instrument.id
			}));
			setInstrumentsOptions(instruments)
		}
		//on récupère la liste des styles musicaux
		if (result?.stylesMusicaux) {
			const stylesMusicaux = result.stylesMusicaux.map(styleMusical => ({
				label: styleMusical.intitule[i18n.language] ?? "",
				value: styleMusical.id
			}));
			setStylesMusicauxOptions(stylesMusicaux)
		}
		//on récupère la liste des emplois
		if (result?.emplois) {
			const emplois = result.emplois.map(emploi => ({
				label: t('emplois.' + emploi),
				value: emploi
			}));
			setEmploisOptions(emplois)
		}
	}

	useEffect(() => {
		if(artisteSelected?.styleMusical && stylesMusicauxOptions.length > 0)
			form.setValue("styleMusical", artisteSelected.styleMusical.id);
	}, [stylesMusicauxOptions, artisteSelected?.styleMusical]);

	useEffect(() => {
		//si on est en edition/duplication. On veut transmettre les données de l'artiste aux formulaires : 1) info artiste 2) cachets 3) salaires. On modifie le format de salaires pour faciliter la chose <.<
		if (currentFormMode !== formMode.IS_CREATION) {
			if (artisteSelected && viewModel) {
				if (currentFormMode === formMode.IS_EDIT) {
					form.setValue("nom", artisteSelected.nom);
					form.setValue("prenom", artisteSelected.prenom);
					form.setValue("email", artisteSelected.email);
					form.setValue("lieuHabitation", artisteSelected.lieuHabitation);
					form.setValue("emploi", artisteSelected.emploi);
					form.setValue("isEngagementDirect", artisteSelected.isEngagementDirect);
				}
				setIsArtisteTitulaire(artisteSelected.isTitulaire);
				setIsArtisteAdditionnel(!artisteSelected.isTitulaire);
				setIsArtistePrincipal(artisteSelected.artistePrincipal);
				setIsArtistGenerationSpedidam(artisteSelected.isGenerationSpedidam);
				let enregistrementCachets: CachetDto[] = [];
				let repetitionCachets: CachetDto[] = [];
				let representationCachets: CachetDto[] = [];
				let diffusionCachets: CachetDto[] = [];
				let concertCachets: CachetDto[] = [];
				let modifiedSalaires: SalairesCusto[] = [];

				artisteSelected.cachets.forEach(elem => {
					const modifiedElem:CachetDto = {
						id: elem.id,
						count: elem.count,
						dates: elem.dates,
						montantBrut: elem.montantBrut,
						tauxChargesPatronales: Math.round(elem.tauxChargesPatronales * 100),
						typePrestation: elem.typePrestation
					}

					switch(elem.typePrestation) {
						case TypePrestation.Enregistrement:
							enregistrementCachets.push(modifiedElem)
						break;
						case TypePrestation.Repetition:
							repetitionCachets.push(modifiedElem)
						break;
						case TypePrestation.Representation:
							representationCachets.push(modifiedElem)
						break;
						case TypePrestation.Diffusion:
							diffusionCachets.push(modifiedElem)
						break;
						case TypePrestation.Concert:
							concertCachets.push(modifiedElem)
						break;
					}
				})
				setEnregistrements(enregistrementCachets)
				setRepetitions(repetitionCachets)
				setRepresentations(representationCachets)
				setDiffusion(diffusionCachets)
				setConcert(concertCachets)

				const lieuDateMapper = (salaireDates: LieuDateDto[]) => {
					return salaireDates.reduce((acc:LieuDateByPrestation[], currentValue:LieuDateDto, index:number, array:LieuDateDto[]) => {
						const newValue:LieuDateByPrestation = {
							typePresta: currentValue.typePrestation,
							dates: array.filter(d => d.typePrestation === currentValue.typePrestation)
						}
						if(!acc.some(d => d.typePresta === currentValue.typePrestation)) acc.push(newValue);
						return acc;
					}, []);
				};

				modifiedSalaires = artisteSelected.salaires.map(salaire => ({
					id: salaire.id,
					montantBrut: salaire.montantBrut,
					tauxChargesPatronales: salaire.tauxChargesPatronales * 100,
					lieuDateByPresta: lieuDateMapper(salaire.dates)
				}));

				setSalaires(modifiedSalaires)
			}
		}

		/** Dates */
		settingDatesOptions();
	}, [artisteSelected, viewModel]);

	// Set par défaut des listes d'options pour les dates
	const settingDatesOptions = useCallback(() => {
		
		if (viewModel?.lieuDatesByType) {
			let defaultOpt = Object.keys(viewModel.lieuDatesByType).map((typePresta:string) => {
				return {
					typePresta: typePresta as keyof typeof TypePrestation,
					options: viewModel.lieuDatesByType[typePresta].map((date:LieuDateDto) => { return {
						label: date.description ? moment(date.date).format('DD/MM/YYYY')  + " (" + date.description +")"  : moment(date.date).format('DD/MM/YYYY'),
						value: date,
					}})
				};
			});

			if (artisteSelected) {
				// Check si des dates existent dans les cachets
				artisteSelected.cachets.forEach(c => {
					if (defaultOpt.find(e => TypePrestation[e.typePresta] === c.typePrestation)) {
						defaultOpt.find(e => TypePrestation[e.typePresta] === c.typePrestation).options = defaultOpt.find(e => TypePrestation[e.typePresta] === c.typePrestation)
							.options.filter(date =>
								!c.dates.find(cachetDate => cachetDate.id === date.value.id)
						);
					}
				});
				// Check si des dates existent dans les salaires
				salaires?.forEach(s => {
					const existingSalaireDates = s.lieuDateByPresta.find(l => defaultOpt.find(e => TypePrestation[e.typePresta] === l.typePresta));
					defaultOpt.find(e => TypePrestation[e.typePresta] === existingSalaireDates.typePresta).options = defaultOpt
						.find(e => TypePrestation[e.typePresta] === existingSalaireDates.typePresta)
						.options.filter(date =>
							!existingSalaireDates.dates.find(salaireDate => salaireDate.id === date.value.id)
						);
					}
				);
			}

			setDatesSelectOptions(defaultOpt);
		}
	}, [viewModel, salaires]);



	/** Envoi des valeurs à l'API */
	const getAllCachets = () => {
		let cachets: (CreateCachetDto | UpdateCachetDto)[] = [];
		Object.keys(viewModel.lieuDatesByType).forEach(elem => {
			const cachetsForThisType = whichCachetValue(elem as keyof typeof TypePrestation).map<CreateCachetDto | UpdateCachetDto>(item => ({
				id: item.id && !item.id.includes('TEMPORARY_') ? item.id : null,
				lieuDateIds: item.dates?.map(i => i.id),
				montantBrut: item.montantBrut,
				tauxChargesPatronales: item.tauxChargesPatronales / 100,
				count: item.count
			}));
			cachets = cachets.concat(cachetsForThisType)
		});
		return cachets;
	}

	const getAllSalaires = () => {
		return salaires.reduce((salairesAcc, currentSalaire) => {
			const finalLieuDateIds = currentSalaire.lieuDateByPresta.reduce((a, c) => a.concat(c.dates.map(e => e.id)), []);
			if (finalLieuDateIds.length) {
				salairesAcc.push({
					id: currentSalaire.id && !currentSalaire.id.includes('TEMPORARY_') ? currentSalaire.id : null,
					lieuDateIds: finalLieuDateIds,
					montantBrut: parseFloat(currentSalaire.montantBrut.toString()),
					tauxChargesPatronales: parseFloat(currentSalaire.tauxChargesPatronales.toString()) / 100,
				});
			}
			return salairesAcc;
		}, []);
	}

	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			const result = await form.triggerValidation();

			if(invalidSalaireDates)
				return false;

			if (result) {
				const formValues = form.getValues() as Record<string, string>;

				const allCachets = getAllCachets();
				const allSalaires = getAllSalaires();

				if (allCachets.filter(x => x.lieuDateIds.length === 0).length > 0) {
					return false
				}

				if (allCachets.length > 0 || allSalaires.length > 0 || isArtisteTitulaire) {
					const body: CreateArtisteDto | UpdateArtisteDto = {
						nom: formValues.nom,
						prenom: formValues.prenom,
						email: formValues.email,
						lieuHabitation: formValues.lieuHabitation,
						emploi: formValues.emploi as Emploi,
						styleMusicalId: formValues.styleMusical,
						instrumentId: formValues.instrumentId && emploi === 'musicien' ? formValues.instrumentId : undefined,
						artistePrincipal: isArtistePrincipal,
						formation: viewModel.formationRequired ? formValues.formation : undefined,
						isGenerationSpedidam: isArtistGenerationSpedidam,
						isTitulaire: isArtisteTitulaire,
						isEngagementDirect: formValues?.isEngagementDirect as unknown as boolean ?? true,
						cachets: allCachets,
						salaires: allSalaires
					}
					try {
						if (currentFormMode === formMode.IS_CREATION || currentFormMode === formMode.IS_DUPLICATE) {
							await dossierClient.createArtiste(dossierId, body);
						} else if (currentFormMode === formMode.IS_EDIT) {
							await artisteClient.updateArtiste(artisteSelected.id, body)
						}
					} catch (error) {
						if (error.additionalDetails && error.code === "maxRangeBetweenDatesExceeded") {
							toast.error(t("createFolder.contratsCachets.maxRangeBetweenDatesExceeded", {number: error.additionalDetails.nbJourMax}))
						} else if (error.additionalDetails && error.code === "maximumDates") {
							toast.error(t("createFolder.contratsCachets.maximumDates", {number: error.additionalDetails.nbJourMax}))
						} else if (error.additionalDetails && error.code === "minCachetRequired") {
							toast.error(
									t("createFolder.contratsCachets.minCachetRequired", {number: error.additionalDetails.nbCachet})
									+' '
									+ t(`createFolder.contratsCachets.${error.additionalDetails.typePrestation}`)
								)
						} else if (error.exception?.message)
							toast.error(error.exception.message);
						else{
							toast.error(t("errors.default"))
						}
						return false;
					}
					closeView();
					return true;
				} else {
					toast.error(t("createFolder.contratsCachets.errorDate"))
					return false;
				}
			} else {
				console.debug("form error detected");
				return false;
			}
		}
	}));

	const whichCachetValue = (type: keyof typeof TypePrestation) => {
		switch (TypePrestation[type]) {
			case TypePrestation.Enregistrement:
				return enregistrements
			case TypePrestation.Repetition:
				return repetitions
			case TypePrestation.Representation:
				return representations
			case TypePrestation.Diffusion:
				return diffusion
			case TypePrestation.Concert:
				return concert
			default:
				return []
		}
	}

	const whichCachetModifier = (type: keyof typeof TypePrestation) => {
		switch (TypePrestation[type]) {
			case TypePrestation.Enregistrement:
				return setEnregistrements
			case TypePrestation.Repetition:
				return setRepetitions
			case TypePrestation.Representation:
				return setRepresentations
			case TypePrestation.Diffusion:
				return setDiffusion
			case TypePrestation.Concert:
				return setConcert
			default:
				return null
		}
	}
    const getArtistPrincipalLabel = (): string => {
		let value =  codeCategorie === CategorieDossier.AideResidences && isArtistePrincipal ? " (soliste, leader de la formation)" : "" 
		return t('createFolder.contratsCachets.listColumns.artistPrincipal') + value
	}
	return <>
		<div className="creationDossier__header--alt">
			<div className="navigationFil" onClick={() => closeView()}>
				<span className="navigationFil__item">
					<i className="fas fa-chevron-left"></i>
					{t("createFolder.contratsCachets.back")}
				</span>
			</div>
			<h2>{t("createFolder.contratsCachets.add")}</h2>
		</div>
		<div className="creationDossier__block">
			<div className="creationDossier__row">
				<Input name="nom"
					label={`${t('createFolder.contratsCachets.listColumns.nom')}`}
					type="text"
					reference={form.register(artisteValidator?.["Nom"])}
					errors={form.errors}
				/>
				<Input name="prenom"
					label={`${t('createFolder.contratsCachets.listColumns.prenom')}`}
					type="text"
					reference={form.register(artisteValidator?.["Prenom"])}
					errors={form.errors}
				/>
			</div>
			<div className="creationDossier__row">
				<Input name="email"
					label={`${t('createFolder.contratsCachets.listColumns.mail')}*`}
					type="text"
					reference={form.register(artisteValidator?.["Email"])}
					errors={form.errors}
				/>
				<Input name="lieuHabitation"
					label={`${t('createFolder.contratsCachets.listColumns.lieuHabitation')}`}
					type="text"
					reference={form.register(artisteValidator?.["LieuHabitation"])}
					errors={form.errors}
				/>
			</div>
			<div className="creationDossier__row">
				{artisteValidator &&
					<Controller
						control={form.control}
						name="emploi"
						as={({onChange, name, value}) => (
							<InputSelect<Emploi>
								name={name}
								label={`${t('createFolder.contratsCachets.listColumns.emploi')}*`}
								classname="inputSelect"
								options={emploisOptions}
								errors={form.errors}
								value={value}
								onChange={onChange}
							/>
						)}
						rules={artisteValidator["Emploi"]}
					/>
				}
				{stylesMusicauxOptions.length > 0 &&
					<Controller
						control={form.control}
						name="styleMusical"
						as={({onChange, name, value}) => (
							<InputSelect
								name={name}
								label={`${t('createFolder.contratsCachets.listColumns.stylemusical')}*`}
								classname="inputSelect"
								isSearchable={true}
								options={stylesMusicauxOptions}
								errors={form.errors}
								onChange={onChange}
								value={value}
							/>
						)}
						rules={{ required: { value: true, message: t('validation-messages.required') } }}
					/>
				}
			</div>
			<div className="creationDossier__row">
				{instrumentsOptions.length > 0 && emploi === 'musicien' &&
				<Controller
					control={form.control}
					name="instrumentId"
					as={({onChange, name, value}) => (
						<InputSelect<string>
							name={name}
							label={`${t('createFolder.contratsCachets.listColumns.instrument')}*`}
							classname="inputSelect"
							options={instrumentsOptions}
							isSearchable={true}
							errors={form.errors}
							value={value}
							onChange={onChange}
						/>
					)}
					rules={{ required: { value: true, message: t('validation-messages.required') } }}
				/>}
			</div>

			{viewModel?.formationRequired && <div className="creationDossier__row">
				<Input name="formation"
					label={`${t('createFolder.contratsCachets.listColumns.formation')}`}
					type="text"
					reference={form.register(artisteValidator?.["Formation"])}
					errors={form.errors}
				/>
			</div>}

			{viewModel?.artistePrincipalInfoRequired && <div className="creationDossier__row">
				<InputSelect
					name="artistPrincipal"
					label={getArtistPrincipalLabel()}
					classname="inputSelect"
					options={YesNotOptions}
					errors={form.errors}
					onChange={(selected) => {
						if(selected === "oui")
							setIsArtistePrincipal(true);
						else
							setIsArtistePrincipal(false);
					}}
					value={isArtistePrincipal === true ? "oui" : "non"}
				/>
			</div>}

			{viewModel?.generationSpedidamInfoRequired && <div className="creationDossier__row">
				<InputSelect
					name="artistGenerationSpedidam"
					label={`${t('createFolder.contratsCachets.listColumns.artistGenerationSpedidam')}*`}
					classname="inputSelect"
					options={YesNotOptions}
					errors={form.errors}
					onChange={(selected) => {
						if(selected === "oui")
							setIsArtistGenerationSpedidam(true);
						else
							setIsArtistGenerationSpedidam(false);
					}}
					value={isArtistGenerationSpedidam === true ? "oui" : "non"}
				/>
			</div>}

			{viewModel?.artisteTitulaireInfoRequired && <div className="creationDossier__row">
				<InputSelect
					name="artistTitulaire"
					label={`${t('createFolder.contratsCachets.listColumns.artistTitulaire')}*`}
					classname="inputSelect"
					options={YesNotOptions}
					errors={form.errors}
					onChange={(selected) => {
						if(selected === "oui") {
							setIsArtisteTitulaire(true);
							setIsArtisteAdditionnel(false);
						} else {
							setIsArtisteTitulaire(false);
							setIsArtisteAdditionnel(true);
						}
					}}
					value={isArtisteAdditionnel === undefined ? "" : isArtisteTitulaire === true ? "oui" : "non"}
				/>
				<div className="input">
					<label>{t('createFolder.contratsCachets.listColumns.artistAdditionnel')}</label>
					<div className="inputContent">{
						isArtisteAdditionnel === undefined ? "" : isArtisteAdditionnel === true ? t('common.yes') : t('common.no')
					}</div>
				</div>
			</div>}
			{(codeCategorie == CategorieDossier.SoireeArtistes || codeCategorie == CategorieDossier.Festival) && 
					<Controller control={form.control}
					name="isEngagementDirect"
					as={({ onChange, name, checked }) => (
						<InputRadioYesNo
						name={name}
						radioLabels={["Artiste Engagement Direct", "Contrat de vente"]}
						label="Artiste engagement direct ?"
						onChange={onChange}
						value={checked}
						/>
					)}
					/>
			}

		</div>
		{viewModel?.lieuDatesByType && Object.keys(viewModel.lieuDatesByType).map((elem, i) => (
			<div className="creationDossier__block creationDossier__contratCachets" key={i}>
				<h2>{t(`createFolder.contratsCachets.Cachet${elem}`)}</h2>
				<Cachets
					cachets={whichCachetValue(elem as keyof typeof TypePrestation)}
					setCachets={whichCachetModifier(elem as keyof typeof TypePrestation)}
					form={form}
					cachetType={elem as keyof typeof TypePrestation}
					datesSelectOptions={datesSelectOptions}
					setDatesSelectOptions={setDatesSelectOptions}
					dates={viewModel.lieuDatesByType[elem]}
					codeCategorie={codeCategorie}
					remuneration={remunerations}
					typePrestation={elem as keyof typeof TypePrestation}
					emploi={emploi}
					groupesEmploi={groupesEmploi}
					styleMusical={styleMusical as string}
				/>
			</div>
		))}
		{viewModel?.mensualisation &&
			<div className="creationDossier__block creationDossier__contratCachets">
				<h2>{t("createFolder.contratsCachets.salaires")}</h2>
				<Salaires
					salaires={salaires}
					setSalaires={setSalaires}
					form={form}
					datesSelectOptions={datesSelectOptions}
					setDatesSelectOptions={setDatesSelectOptions}
					dates={viewModel.lieuDatesByType}
					currentFormMode={currentFormMode}
					remuneration={remunerations}
					groupesEmploi={groupesEmploi}
					emploi={emploi}
					styleMusical={styleMusical as string}
					setInvalidSalaireDates={setInvalidSalaireDates}
				/>
			</div>
		}
	</>;
});

export default EditArtiste;