import React, { useEffect, useState } from 'react';
import {
    DossierDetailsDto,
    LieuDateDetailsDto,
	DossierInfosProjetViewModelDto,
	DescriptionProjetViewModelDto,
	DocumentWithValidationDto
} from '../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import DocumentValidationLight from '../detailsDossier/DocumentValidationLight';
import Artistes from 'adel-shared/dist/components/DossierDetails/Artistes';
import DeplacementTable from 'adel-shared/dist/components/DossierDetails/DeplacementsTable';
import Programmation from 'adel-shared/dist/components/DossierDetails/Programmation';
import BudgetsPrevisionnels from 'adel-shared/dist/components/DossierDetails/BudgetsPrevisionnels';
import InformationsBudget from 'adel-shared/dist/components/DossierDetails/InformationsBudget';
import Structure from 'adel-shared/dist/components/DossierDetails/Structure';
import Enseignants from 'adel-shared/dist/components/DossierDetails/Enseignants';
import Salaries from 'adel-shared/dist/components/DossierDetails/Salaries';
import ChiffresCles from 'adel-shared/dist/components/DossierDetails/ChiffresCles';
import InfosProjet from 'adel-shared/dist/components/DossierDetails/InfosProjet';
import DescriptionProjet from 'adel-shared/dist/components/DossierDetails/DescriptionProjet';
import Contenu from 'adel-shared/dist/components/DossierDetails/Contenu';
import { CategorieDossier } from 'adel-shared/dist/enums/CategorieDossier';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface DetailsDossierContentProps {
	dossierDetails: DossierDetailsDto;
	descrProj: DescriptionProjetViewModelDto;
	infosProjet: DossierInfosProjetViewModelDto;
}

const DetailsDossierContent: React.FunctionComponent<DetailsDossierContentProps> = ({dossierDetails, descrProj, infosProjet}) => {
	const { t, i18n } = useTranslation();

    const handlePrintClick = () => {
        window.print();
    };

    const datesByPrestation = dossierDetails.dates?.length ? dossierDetails.dates.reduce((accumulator: LieuDateDetailsDto[][], currentVal) => {
        const date = accumulator.filter(
            x => x.some(
                y => y.typePrestation === currentVal.typePrestation
            )
        );

        if (date.length) {
            date[0].push(currentVal);
        } else {
            accumulator.push([currentVal]);
        }

        return accumulator;
    }, []) : [];

    const [pieceObligatoires, setPieceObligatoires] = useState<DocumentWithValidationDto[]>([]);
    const [pieceJointe, setPieceJointe] = useState<DocumentWithValidationDto[]>([]);

	useEffect(() => {
		if (dossierDetails.documents) {
			const docO = dossierDetails.documents.filter(d => d.type?.isRequired) || [];
			const docJ = dossierDetails.documents.filter(d => !d.type?.isRequired) || [];
			setPieceObligatoires(docO);
			setPieceJointe(docJ);
		}
	}, [dossierDetails.documents]);

    return (<>
		<div className="detailsTab__section">
			<h3>{t("detailsDossier.structure")}</h3>
			<Structure
				details={dossierDetails}
			/>
		</div>

		<div className="detailsTab__section">
			<h3>{t("detailsDossier.infosDossier.title")}</h3>

			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label={t("detailsDossier.infosDossier.numero")}
							content={dossierDetails.numero || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label={t("detailsDossier.infosDossier.type")}
							content={dossierDetails.categorie?.nom || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label={t("detailsDossier.infosDossier.nom")}
							content={dossierDetails.nom || '-'}
						/>
					</div>
				</div>
			</div>
			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label={t("detailsDossier.infosDossier.date")}
							content={dossierDetails.dateDebut && dossierDetails.dateFin ? `du ${formaterDate(dossierDetails.dateDebut)} au ${formaterDate(dossierDetails.dateFin)}` : '-'}
						/>
					</div>
				</div>
			</div>
			
			{infosProjet &&
				<InfosProjet
				//@ts-ignore
					details={infosProjet}
					lang={i18n.language}
				/>
			}
		</div>

		{pieceObligatoires.length > 0 &&
			<div className="detailsTab__section">
				<h3>{t("detailsDossier.documentsObligatoires")}</h3>
				<DocumentValidationLight
					documents={pieceObligatoires}
				/>
			</div>
		}
		{pieceJointe.length > 0 &&
			<div className="detailsTab__section">
				<h3>{t("detailsDossier.documents")}</h3>
				<DocumentValidationLight
					documents={pieceJointe}
				/>
			</div>
		}
		{(dossierDetails.categorie?.code != CategorieDossier.Festival 
		&& dossierDetails.categorie?.code != CategorieDossier.SoireeArtistes) ?
									dossierDetails?.artistes && dossierDetails.artistes.length > 0 &&

		<div className="detailsTab__section">
				<h3>{t("detailsDossier.artistes")}</h3>
				<Artistes
					details={dossierDetails}
					isEngagementDirect={true}
					isResponsableAndMembreCommission={false}
				/>
			</div>
			:
		(dossierDetails.artistes && dossierDetails.artistes.filter(a => a.isEngagementDirect).length > 0) &&
			<div className="detailsTab__section">
				<h3>Artistes engagement direct</h3>
				<Artistes
					details={dossierDetails}
					isEngagementDirect={true}
					isResponsableAndMembreCommission={false}
				/>
			</div>
		}
		{(dossierDetails.artistes && dossierDetails.artistes.filter(a => !a.isEngagementDirect).length > 0) &&
			<div className="detailsTab__section">
				<h3>Artistes issus des contrats de cession</h3>
				<Artistes
					details={dossierDetails}
					isEngagementDirect={false}
					isResponsableAndMembreCommission={false}
				/>
			</div>
		}
		{dossierDetails.trajets?.length > 0 &&
			<div className="detailsTab__section">
				<h3>{t("detailsDossier.deplacements")}</h3>
				<DeplacementTable
					trajets={dossierDetails.trajets}
				/>
			</div>
		}
		
		{dossierDetails?.salaries && dossierDetails.salaries.length > 0 &&
			<section className="detailsTab__section">
				<h3>{t("detailsDossier.salarie")}</h3>
				<Salaries salarie={dossierDetails.salaries[0]} />
			</section>
		}
		{dossierDetails?.enseignants && dossierDetails.enseignants.length > 0 &&
			<section className="detailsTab__section">
				<h3>{t("detailsDossier.enseignants")}</h3>
				<Enseignants enseignants={dossierDetails.enseignants} />
			</section>
		}
		{dossierDetails?.chiffresCles &&
			<section className="detailsTab__section">
				<h3>{t("detailsDossier.chiffres")}</h3>
				<ChiffresCles chiffres={dossierDetails.chiffresCles} />
			</section>
		}

		{datesByPrestation?.map((x, i) => (
			<div key={i} className="detailsTab__section">
				<h3>{t("detailsDossier.programmation")} {t(`structure.prestation.${x[0].typePrestation}`)}s ({x.length})</h3>
				<Programmation
					dates={x}
					categorieDossier={dossierDetails?.categorie}
				/>
			</div>
		))}

		<div className="detailsTab__section">
			<h3>{t("detailsDossier.budgets.title")}</h3>

			<div className="detailsTab__sectionSub">
				<BudgetsPrevisionnels
					budget={dossierDetails.budget}
					categorie={dossierDetails.categorie}
				/>
			</div>

			<div className="detailsTab__sectionSub">
				<h4>{t("detailsDossier.budgets.infos")}</h4>
				<InformationsBudget
					budget={dossierDetails.budget}
					isDetailDossierTab
				/>
			</div>

			<div className="detailsTab__sectionSub">
				<h4>{t("detailsDossier.budgets.autre")}</h4>
				<DocumentValidationLight
					documents={dossierDetails.budget?.documents}
				/>
			</div>
		</div>

		<div className="detailsTab__section">
			<h3>{t("detailsDossier.contenu")}</h3>
			<Contenu details={dossierDetails} />
			{descrProj &&
			//@ts-ignore
				<DescriptionProjet details={descrProj} lang={i18n.language} />
			}
		</div>

		<div className="detailsTab__section">
			<h3>{t("detailsDossier.commentaires")}</h3>
			<textarea
				className="textarea"
				defaultValue={dossierDetails.commentaires}
				disabled
			>
			</textarea>
		</div>

		<div className="detailsDossier__footer">
			<div className="detailsDossier__btn">
				<FormButton
					type="button"
					value={t('detailsFolder.imprimer')}
					onClick={handlePrintClick}
				/>
			</div>
		</div>

    </>);
};

export default DetailsDossierContent;